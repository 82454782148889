import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Select, Spin, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import * as actionCreators from '../actions';

export default function AttributeMaintenance({ toggleView }) {
  const dispatch = useDispatch();

  // selectors
  const businessCenterList = useSelector(state => _.get(state.businessCenters, 'businessCenters', []));
  const itemGroupList = useSelector(state => _.get(state.itemGroups, 'itemGroups', []));
  const attributeGroupList = useSelector(state => _.get(state.attributeGroups, 'attributeGroups', []));
  const isLoadingIg = useSelector(state => _.get(state.isLoadingItemGroup, 'isLoadingItemGroup', false));
  const isLoadingAg = useSelector(state => _.get(state.isLoadingAttributeGroup, 'isLoadingAttributeGroup', false));
  const isLoadingBc = useSelector(state => _.get(state.isLoadingBusinessCenters, 'isLoadingBusinessCenters', false));
  const user = useSelector(state => _.get(state, 'user', []));
  const rainbowAttributes = useSelector(state => _.get(state.rainbowAttributes, 'rainbowAttributes', []));
  const isLoadingRainbowAttributes = useSelector(state => _.get(state.rainbowAttributes, 'isLoading', false));
  const isSavingAttribute = useSelector(state => _.get(state.saveRainbowAttribute, 'isSavingAttribute', false));
  // states
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedBusinessCenter, setSelectedBusinessCenter] = useState(null);
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [data, setData] = useState([]);
  const [updatedAttributes, setUpdatedAttributes] = useState([]);
  /**
   * fetching all business centers.
   */
  useEffect(() => {
    if (businessCenterList.length === 0) {
      dispatch(actionCreators.fetchBusinesscenters());
    }
  }, [businessCenterList]);

  /**
   * fetching all item groups.
   */
  useEffect(() => {
    if (selectedBusinessCenter) {
      dispatch(actionCreators.fetchItemGroups({ bc: selectedBusinessCenter }));
    }
  }, [selectedBusinessCenter]);

  /**
   * fetching all attribute groups.
   */
  useEffect(() => {
    if (selectedBusinessCenter && selectedItemGroup) {
      dispatch(actionCreators.fetchAttributeGroups({ bc: selectedBusinessCenter, ig: selectedItemGroup }));
    }
  }, [selectedBusinessCenter, selectedItemGroup]);

  useEffect(() => {
    setPermissions(user?.data?.permissions);
  }, [user]);

  useEffect(() => {
    setData(rainbowAttributes?.data);
  }, [rainbowAttributes]);

  useEffect(() => {
    if (!isSavingAttribute) {
      setUpdatedAttributes([]);
    }
  }, [isSavingAttribute]);

  const handleSearchButtonClick = () => {
    console.log('Fetch attribute values');
    dispatch(
      actionCreators.fetchAllRainbowAttributeDetails({
        bc: businessCenterList.find(center => center.bcName === selectedBusinessCenter)?.osdBcCode,
        ig: itemGroupList.find(center => center.igName === selectedItemGroup)?.osdIgCode,
        ag: attributeGroupList.find(center => center.agName === selectedAttributeGroup)?.osdAgCode
      })
    );
  };

  const handleBusinessCenterChange = businessCenter => {
    if (updatedAttributes.length > 0) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content:
          'You have unsaved changes. Are you sure you want to change the Business Center and lose these changes?',
        okText: 'Yes, Proceed',
        cancelText: 'Cancel',
        onOk: () => {
          // Proceed with the change
          setSelectedBusinessCenter(businessCenter);
          setSelectedItemGroup(null);
          setSelectedAttributeGroup(null);
          setData([]);
          setUpdatedAttributes([]);
        },
        onCancel: () => {
          // Handle cancel action
          console.log('User canceled the action.');
        }
      });
    } else {
      // Proceed normally if there are no unsaved changes
      setSelectedBusinessCenter(businessCenter);
      setSelectedItemGroup(null);
      setSelectedAttributeGroup(null);
      setData([]);
      setUpdatedAttributes([]);
    }
  };

  const handleItemGroupChange = itemGroup => {
    if (updatedAttributes.length > 0) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Are you sure you want to change the Item Group and lose these changes?',
        okText: 'Yes, Proceed',
        cancelText: 'Cancel',
        onOk: () => {
          // Proceed with the change
          setSelectedItemGroup(itemGroup);
          setSelectedAttributeGroup(null);
          setData([]);
          setUpdatedAttributes([]);
        },
        onCancel: () => {
          // Handle cancel action
          console.log('User canceled the action.');
        }
      });
    } else {
      // Proceed normally if there are no unsaved changes
      setSelectedItemGroup(itemGroup);
      setSelectedAttributeGroup(null);
      setData([]);
      setUpdatedAttributes([]);
    }
  };

  const handleAttributeGroupChange = attributeGroup => {
    if (updatedAttributes.length > 0) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content:
          'You have unsaved changes. Are you sure you want to change the Attribute Group and lose these changes?',
        okText: 'Yes, Proceed',
        cancelText: 'Cancel',
        onOk: () => {
          // Proceed with the change
          setSelectedAttributeGroup(attributeGroup);
          setData([]);
          setUpdatedAttributes([]);
        },
        onCancel: () => {
          // Handle cancel action
          console.log('User canceled the action.');
        }
      });
    } else {
      // Proceed normally if there are no unsaved changes
      setSelectedAttributeGroup(attributeGroup);
      setData([]);
      setUpdatedAttributes([]);
    }
  };

  const handleCheckboxChange = (flag, index, checked, record) => {
    const newData = [...data];
    newData[index][flag] = checked ? 1 : 0; // Update the value (1 for checked, 0 for unchecked)
    setData(newData);
    // Extract the latest updated record from newData
    const updatedRecord = newData[index];
    // Replace the existing record in updatedAttributes or add the latest record
    const updatedRecords = [
      ...updatedAttributes.filter(attr => attr.attributeId !== updatedRecord.attributeId), // Remove old record
      updatedRecord // Add the latest record
    ];
    // Update the updatedAttributes state
    setUpdatedAttributes(updatedRecords);
  };

  const columns = [
    {
      title: 'Attribute Name',
      dataIndex: 'attributeName',
      key: 'attributeName',
      align: 'left'
    },
    {
      title: 'Catman Priority',
      dataIndex: 'catmanPriority',
      key: 'catmanPriority',
      align: 'center'
    },
    {
      title: 'SA1 Flag',
      dataIndex: 'sa1Flag',
      key: 'sa1Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('sa1Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'SA2 Flag',
      dataIndex: 'sa2Flag',
      key: 'sa2Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('sa2Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'SA3 Flag',
      dataIndex: 'sa3Flag',
      key: 'sa3Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('sa3Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'SA4 Flag',
      dataIndex: 'sa4Flag',
      key: 'sa4Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('sa4Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'SA5 Flag',
      dataIndex: 'sa5Flag',
      key: 'sa5Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('sa5Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'VA1 Flag',
      dataIndex: 'va1Flag',
      key: 'va1Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('va1Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'VA2 Flag',
      dataIndex: 'va2Flag',
      key: 'va2Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('va2Flag', index, e.target.checked, record)} />
      )
    },
    {
      title: 'VA3 Flag',
      dataIndex: 'va3Flag',
      key: 'va3Flag',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox checked={!!value} onChange={e => handleCheckboxChange('va3Flag', index, e.target.checked, record)} />
      )
    }
  ];

  const saveAttributeChnges = () => {
    console.log('Save attribute changes');
    dispatch(actionCreators.saveAllRainbowAttributeDetails({ payload: updatedAttributes }));
  };

  return (
    <>
      <div className={`grid-filter-panel `} id="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="title">Item Relationship Pairs</div>
      </div>
      <div style={{ margin: '11px 25px -7px 25px', fontSize: '16px', fontFamily: 'BrixSansRegular' }}>
        Welcome Back , to begin select a Business center, item group, and attribute group.
      </div>
      <div className={`filter-wrapper `} id="filter-wrapper">
        <div className="filterbar">
          <div className="filtergroup market">
            <div className="filtergroup-title">BUSINESS CENTER</div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Business center"
                  disabled={businessCenterList.length <= 0}
                  onChange={handleBusinessCenterChange}
                  value={selectedBusinessCenter}
                  loading={isLoadingBc}
                >
                  {businessCenterList.length > 0 &&
                    businessCenterList
                      .sort((a, b) => a.bcName.localeCompare(b.bcName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.bcName} value={obj.bcName}>
                            {obj.bcName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
          </div>
          <div className="filtergroup item-grp">
            <div className="filtergroup-title">ITEM GROUP</div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Item Group"
                  disabled={itemGroupList.length <= 0 || selectedBusinessCenter == null}
                  onChange={handleItemGroupChange}
                  value={selectedItemGroup}
                  loading={isLoadingIg}
                >
                  {itemGroupList.length > 0 &&
                    itemGroupList
                      .sort((a, b) => a.igName.localeCompare(b.igName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.igName} value={obj.igName}>
                            {obj.igName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
          </div>
          <div className="filtergroup attribute-grp">
            <div className="filtergroup-title">ATTRIBUTE GROUP</div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Item Group"
                  disabled={
                    attributeGroupList.length <= 0 || selectedBusinessCenter == null || selectedItemGroup == null
                  }
                  value={selectedAttributeGroup}
                  loading={isLoadingAg}
                  onChange={handleAttributeGroupChange}
                >
                  {attributeGroupList.length > 0 &&
                    attributeGroupList
                      .sort((a, b) => a.agName.localeCompare(b.agName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.agName} value={obj.agName}>
                            {obj.agName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
          </div>

          <div className="filtergroup">
            <div className="filtergroup-title filter-title-search"></div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Button
                  type="primary"
                  className="btn searchbtn"
                  style={{ width: '100px' }}
                  onClick={handleSearchButtonClick}
                  disabled={!(selectedBusinessCenter && selectedAttributeGroup && selectedItemGroup)}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Attribute table */}
      <Spin spinning={isLoadingRainbowAttributes || isSavingAttribute}>
        <Table
          scroll={{ x: true, y: window.innerHeight - 415 }}
          columns={columns}
          dataSource={data
            ?.sort((a, b) => a.catmanPriority - b.catmanPriority) // Sort in ascending order
            .map(item => ({
              ...item,
              key: item.attributeId // Unique key for each row
            }))}
          pagination={false}
          rowClassName={'uniform-rows'}
          size="small"
          showHeader={data && data.length > 0 ? true : false}
        />
      </Spin>

      {/* footer */}
      <div className="footer-base">
        <div className="footer-left">
          <div className="footer-left-buttons">
            <Button
              type="primary"
              onClick={saveAttributeChnges}
              disabled={!(updatedAttributes.length > 0) || !permissions.includes('BRANDCONVERSIONAPP.EDIT')}
            >
              SUBMIT CHANGES
            </Button>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <>
                  <Button type="primary" onClick={toggleView} style={{ marginRight: '10px' }}>
                    MAIN SCREEN
                  </Button>
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-right"></div>
      </div>
    </>
  );
}
