import { put } from 'redux-saga/effects';
import { deleteRequest, getRequest, postRequest } from '_http';
import { AGENTS, AGENT_DETAILS, CREATE_AGENT, DELETE_AGENT, LOAD_SITE } from 'actions/actionTypes';

import _ from 'lodash';
import { action } from 'reduxHelpers';

// TODO: Temp
const TEMP_AGENT_DETAILS = {
  email: 'damith.slabz@gmail.com',
  contactId: '1',
  username: 'damith.slabz@gmail.com',
  phone: [
    {
      phoneId: 4110,
      number: '+11111111111',
      type: 'Primary'
    }
  ],
  title: 'Supplier',
  name: 'Damith'
};

function* loadAgentsAsync({ marketId }) {
  try {
    console.log(`marketId---------------`, marketId);
    const response = yield getRequest(`/sales/users/markets/${marketId}`);
    yield put({ type: AGENTS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: AGENTS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'AGENT LOADING ERROR'
      })
    );
  }
}

function* loadAgentDetailsAsync({ contactId }) {
  try {
    const response = yield getRequest(`/sales/users/${contactId}`);
    yield put({ type: AGENT_DETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: AGENT_DETAILS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'AGENTS LOADING ERROR'
      })
    );
  }
}

function* saveAgentAsync(data) {
  console.log('saveAgentAsync ~~~~ ', data);

  let request = {
    market: data.market,
    email: data.email,
    phone: (data.phone || []).map(({ number, type }) => ({ number, type })),
    name: data.name,
    title: data.title,
    site: data.site
  };

  let networkId = `${data.networkId}`.toLowerCase().trim();

  try {
    const response = yield postRequest(`/sales/users/${networkId}`, request);
    yield put({
      type: CREATE_AGENT.SUCCESS,
      payload: { ...response.data, contactId: networkId, networkId, update: data.update }
    });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'User Created!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    console.log(`error*****^^^^`, error);
    yield put({ type: CREATE_AGENT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CREATE AGENT ERROR'
      })
    );
  }
}

function* deleteAgentAsync({ contactId }) {
  console.log('contactId', contactId);
  try {
    yield deleteRequest(`/sales/users/${contactId}`);
    yield put({ type: DELETE_AGENT.SUCCESS, payload: contactId });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'User Deleted!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: DELETE_AGENT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'DELETE AGENT ERROR'
      })
    );
  }
}

// TODO: Temp
function* sleep(time, data) {
  let results = yield new Promise(resolve => {
    setTimeout(() => {
      resolve({ data });
    }, time);
  });

  return results;
}

function* loadSitesAsync() {
  try {
    const response = yield getRequest(`/sales/sites`);
    yield put({ type: LOAD_SITE.SUCCESS, payload: response.data.data });
  } catch (error) {
    yield put({ type: LOAD_SITE.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'LOAD SITES ERROR'
      })
    );
  }
}

export { loadAgentsAsync, loadAgentDetailsAsync, saveAgentAsync, deleteAgentAsync, loadSitesAsync };
