import initialState from 'store/initialState';
import { CHANGE_LOGS } from 'actions/actionTypes';

const changeLog = (state = initialState.changeLog, action) => {
  switch (action.type) {
    case CHANGE_LOGS.REQUEST:
      return { ...state, isLoadingChangeLog: true };
    case CHANGE_LOGS.SUCCESS:
      return {
        ...state,
        isLoadingChangeLog: false,
        changeLog: action.payload.changeLogData.data 
      };
    case CHANGE_LOGS.FAILURE:
      return { ...state, isLoadingChangeLog: false };
    default:
      return state;
  }
};

export default changeLog;
