import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
// For table items
const ITEMLIST = createRequestTypes('ITEMLIST');
const BUSINESS_CENTERS = createRequestTypes('BUSINESS_CENTERS');
const ITEM_GROUPS = createRequestTypes('ITEM_GROUPS');
const ATTRIBUTE_GROUPS = createRequestTypes('ATTRIBUTE_GROUPS');
const PAIR_TYPES = createRequestTypes('PAIR_TYPES');
const FILTER_PARAMS = createRequestTypes('FILTER_PARAMS');
const DO_NOT_CONVERT_BRANDS = createRequestTypes('DO_NOT_CONVERT_BRANDS');
const DO_NOT_CONVERT_BRANDS_DELETE = createRequestTypes('DO_NOT_CONVERT_BRANDS_DELETE');
const ALL_BRANDS = createRequestTypes('ALL_BRANDS');
const DO_NOT_CONVERT_BRANDS_ADD = createRequestTypes('DO_NOT_CONVERT_BRANDS_ADD');
const DISTINCT_SUPC = createRequestTypes('DISTINCT_SUPC');
const NEW_PAIR = createRequestTypes('NEW_PAIR');
const ITEM_CHANGES = createRequestTypes('ITEM_CHANGES');
const COLUMN_FILTERS = createRequestTypes('COLUMN_FILTERS');
const COLUMN_FILTERS_REMOVE = createRequestTypes('COLUMN_FILTERS_REMOVE');
const COLUMN_SORTS = createRequestTypes('COLUMN_SORTS');
const COLUMN_SORTS_REMOVE = createRequestTypes('COLUMN_SORTS_REMOVE');
const VALID_ATTRIBUTES = createRequestTypes('VALID_ATTRIBUTES');
const VALIDATE_PAIR = createRequestTypes('VALIDATE_PAIR');
const QUALIFIERS = createRequestTypes('QUALIFIERS');
const MATCH_QUALIFIERS = createRequestTypes('MATCH_QUALIFIERS');
const SUGGESTED_NEW_PAIRS = createRequestTypes('SUGGESTED_NEW_PAIRS');
const UPDATE_SUGGESTED_NEW_PAIRS = createRequestTypes('UPDATE_SUGGESTED_NEW_PAIRS');
const CHANGE_LOGS = createRequestTypes('CHANGE_LOGS');
const ALL_CHANGE_LOGS = createRequestTypes('ALL_CHANGE_LOGS');
const RAINBOW_ATTRIBUTES = createRequestTypes('RAINBOW_ATTRIBUTES');
const SAVE_RAINBOW_ATTRIBUTES = createRequestTypes('SAVE_RAINBOW_ATTRIBUTES');
export {
  USER,
  ITEMLIST,
  BUSINESS_CENTERS,
  ITEM_GROUPS,
  ATTRIBUTE_GROUPS,
  PAIR_TYPES,
  FILTER_PARAMS,
  DO_NOT_CONVERT_BRANDS,
  DO_NOT_CONVERT_BRANDS_DELETE,
  ALL_BRANDS,
  DO_NOT_CONVERT_BRANDS_ADD,
  DISTINCT_SUPC,
  NEW_PAIR,
  ITEM_CHANGES,
  COLUMN_FILTERS,
  COLUMN_FILTERS_REMOVE,
  COLUMN_SORTS,
  COLUMN_SORTS_REMOVE,
  VALID_ATTRIBUTES,
  VALIDATE_PAIR,
  QUALIFIERS,
  MATCH_QUALIFIERS,
  SUGGESTED_NEW_PAIRS,
  UPDATE_SUGGESTED_NEW_PAIRS,
  CHANGE_LOGS,
  ALL_CHANGE_LOGS,
  RAINBOW_ATTRIBUTES,SAVE_RAINBOW_ATTRIBUTES

};
