import { combineReducers } from 'redux';
import user from './UserReducer';
import itemList from './itemListReducer';
import businessCenters from './businessCentersReducer';
import itemGroups from './itemGroupsReducer';
import attributeGroups from './attributeGroupsReducer';
import pairTypes from './pairTypesReducer';
import filterParams from './filterParamsReducer';
import doNotConvertBrands from './doNotConvertBrandsReducer';
import supcList from './supcListReducer';
import newPair from './newPairReducer';
import itemChanges from './itemChangesReducer';
import columnFilters from './columnFiltersReducer';
import columnSorters from './columnSortersReducer';
import validAttributes from './validAttributesReducer';
import validateNewPair from './validateNewPairReducer';
import qualifiers from './qualifierReducer';
import matchQualifiers from './matchQualifierReducer';
import suggestedPairList from './suggestedPairListReducer';
import updateSuggestedPair from './updateSuggestedPairReducer';
import changeLog from './changeLogReducer';
import allChangeLog from './allChangeLogReducer';
import rainbowAttributes from './rainbowAttributesReducer';
import saveRainbowAttribute from './saveRainbowAttributeReducer';
export default combineReducers({
  user,
  itemList,
  businessCenters,
  itemGroups,
  attributeGroups,
  pairTypes,
  filterParams,
  doNotConvertBrands,
  supcList,
  newPair,
  itemChanges,
  columnFilters,
  columnSorters,
  validAttributes,
  validateNewPair,
  qualifiers,
  matchQualifiers,
  suggestedPairList,
  updateSuggestedPair,
  changeLog,
  allChangeLog,
  rainbowAttributes,
  saveRainbowAttribute
});
