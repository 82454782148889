import user from './InitialState/userInitialState';
import itemList from './InitialState/itemDetailsInitialState';
import businessCenters from './InitialState/businesscentersInitialState';
import itemGroups from './InitialState/itemGroupsInitialState';
import attributeGroups from './InitialState/attributeGroupsInitialState';
import pairTypes from './InitialState/pairTypesInitialState';
import filterParams from './InitialState/filterParamsInitialState';
import doNotConvertBrands from './InitialState/doNotConverBrandsInitialState';
import supcList from './InitialState/supcListInitialState';
import newPair from './InitialState/addNewSupcInitialState';
import itemChanges from './InitialState/itemChangesInitialState';
import columnFilters from './InitialState/columnFiltersInitialState';
import columnSorts from './InitialState/columnSortsInitialState';
import validAttributes from './InitialState/validAttributesInitialState';
import validNewPair from './InitialState/validateNewSupcInitialState';
import qualifiers from './InitialState/qualifiersInitialState';
import matchQualifiers from './InitialState/matchQualifiersInitialState';
import suggestedPairs from './InitialState/suggestedPairsInitialState';
import updateSuggestedPairs from './InitialState/updateSuggestedPairsInitialState';
import changeLog from './InitialState/changeLogInitialState';
import allChangeLog from './InitialState/allChangeLogInitialState';
import rainbowAttributes from './InitialState/rainbowAttributesInitialState';
import saveRainbowAttributes from './InitialState/saveRainbowAttributeInitialState';
const initialState = {
  user,
  itemList,
  businessCenters,
  attributeGroups,
  itemGroups,
  pairTypes,
  filterParams,
  doNotConvertBrands,
  supcList,
  newPair,
  itemChanges,
  columnFilters,
  columnSorts,
  validAttributes,
  validNewPair,
  qualifiers,
  matchQualifiers,
  suggestedPairs,
  updateSuggestedPairs,
  changeLog,
  allChangeLog,
  rainbowAttributes,
  saveRainbowAttributes
};
export default initialState;
