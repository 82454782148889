import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Modal, Row, Select, Spin, Table } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../actions';
import moment from 'moment';
import { unparse } from 'papaparse';

const ChangeLogModal = ({ isVisible, handleCancel }) => {
  const pageSize = 10;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  //selectors
  const changeLog = useSelector(state => _.get(state.changeLog, 'changeLog', []));
  const isLoadingChangeLog = useSelector(state => _.get(state.changeLog, 'isLoadingChangeLog', false));
  const allChangeLog = useSelector(state => _.get(state.allChangeLog, 'allChangeLog', []));
  const isLoadingAllChangeLog = useSelector(state => _.get(state.allChangeLog, 'isLoadingAllChangeLog', false));

  const [selectedDates, setSelectedDates] = useState(null); // State to store date range
  const [selectedOption, setSelectedOption] = useState(null); // State to store dropdown selection
  const [changeLogRecords, setChangeLogRecords] = useState([]);
  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (changeLog?.data?.data && selectedDates && selectedOption) {
      console.log('API Response:', changeLog);
      const formattedData = changeLog.data.data.map((item, index) => ({
        ...item,
        key: item.id || index // Ensure 'key' is assigned for unique identification
      }));
      setChangeLogRecords(formattedData); // Update the records state
    }
  }, [changeLog]);

  useEffect(() => {
    if (selectedOption && selectedDates && currentPage > 0) {
      let payload = {};
      let formattedDates = [];
      if (selectedDates) {
        formattedDates = [
          moment(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          moment(selectedDates[1])
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        ];
        payload.changeAt = formattedDates;
      }

      dispatch(
        actionCreators.fetchChangeLogDetails({
          reportType: selectedOption,
          searchParams: payload,
          sortParams: sort,
          pageNo: currentPage - 1,
          pageSize: pageSize
        })
      );
    }
  }, [currentPage, sort]);

  useEffect(() => {
    if (allChangeLog.length > 0 && selectedDates && selectedOption) {
      console.log(allChangeLog.length);
      const dataWithoutId = allChangeLog.map(({ id, ...rest }) => rest);
      downloadCsv(dataWithoutId);
    }
  }, [allChangeLog]);

  const downloadCsv = data => {
    // Convert JSON to CSV
    const csv = unparse(data);
    // Get the current date and time
    const now = new Date();
    const timestamp = now
      .toISOString()
      .slice(0, 19)
      .replace('T', '_')
      .replace(/:/g, '-');

    // Create the file name
    const fileName = `${selectedOption}_${timestamp}.csv`;
    // Create Blob and initiate download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleModalCancel = () => {
    form.resetFields();
    setSelectedDates(null);
    setSelectedOption(null);
    setChangeLogRecords([]);
    handleCancel(false);
  };

  // Method to handle date range changes
  const handleDateChange = (dates, dateStrings) => {
    setChangeLogRecords([]);
    setSelectedDates(dateStrings); // dateStrings will have the formatted start and end dates
    console.log('Selected Dates:', dateStrings); // Log the selected range
    setCurrentPage(1);
  };

  // Method to handle dropdown selection changes
  const handleDropdownChange = value => {
    setChangeLogRecords([]);
    setSelectedOption(value); // Store the selected dropdown option
    console.log('Selected Option:', value); // Log the selected option
    setCurrentPage(1);
  };

  // Method to handle search button click
  const handleSearch = () => {
    setChangeLogRecords([]);
    let payload = {};
    let formattedDates = [];
    if (selectedDates) {
      formattedDates = [
        moment(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        moment(selectedDates[1])
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      ];
      payload.changeAt = formattedDates;
    }

    dispatch(
      actionCreators.fetchChangeLogDetails({
        reportType: selectedOption,
        searchParams: payload,
        pageNo: 0,
        pageSize: pageSize
      })
    );
  };

  const columnsDoNotConvertBrand = [
    {
      title: 'Change At',
      dataIndex: 'changeAt',
      key: 'changeAt',
      render: changeAt => (changeAt ? new Date(changeAt).toLocaleString() : 'N/A'),
      sorter: (a, b) => new Date(a.changeAt || 0).getTime() - new Date(b.changeAt || 0).getTime(),
      width: '100px'
    },
    {
      title: 'Brand Code',
      dataIndex: 'brandCode',
      key: 'brandCode',
      sorter: (a, b) => (a.brandCode && b.brandCode ? a.brandCode.localeCompare(b.brandCode) : 0),
      width: '100px'
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      sorter: (a, b) => (a.brandName && b.brandName ? a.brandName.localeCompare(b.brandName) : 0),
      width: '100px'
    },
    {
      title: 'Update Type',
      dataIndex: 'updateType',
      key: 'updateType',
      sorter: (a, b) => (a.updateType && b.updateType ? a.updateType.localeCompare(b.updateType) : 0),
      width: '100px'
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: updatedBy => updatedBy || 'N/A',
      sorter: (a, b) => (a.updatedBy && b.updatedBy ? a.updatedBy.localeCompare(b.updatedBy) : 0),
      width: '100px'
    }
  ];

  const columnsPairs = [
    {
      title: 'Change At',
      dataIndex: 'changeAt',
      key: 'changeAt',
      render: changeAt => (changeAt ? new Date(changeAt).toLocaleString() : 'N/A'),
      sorter: (a, b) => new Date(a.changeAt || 0).getTime() - new Date(b.changeAt || 0).getTime(),
      width: '80px'
    },
    {
      title: 'From SUPC',
      dataIndex: 'fromSupc',
      key: 'fromSupc',
      sorter: (a, b) => (a.fromSupc && b.fromSupc ? a.fromSupc.localeCompare(b.fromSupc) : 0),
      width: '80px'
    },
    {
      title: 'To SUPC',
      dataIndex: 'toSupc',
      key: 'toSupc',
      sorter: (a, b) => (a.toSupc && b.toSupc ? a.toSupc.localeCompare(b.toSupc) : 0),
      width: '80px'
    },
    {
      title: 'Pair Type',
      dataIndex: 'pairType',
      key: 'pairType',
      sorter: (a, b) => (a.pairType && b.pairType ? a.pairType.localeCompare(b.pairType) : 0),
      width: '80px'
    },
    {
      title: 'Update Type',
      dataIndex: 'updateType',
      key: 'updateType',
      sorter: (a, b) => (a.updateType && b.updateType ? a.updateType.localeCompare(b.updateType) : 0),
      width: '80px'
    },
    {
      title: 'Changed From',
      dataIndex: 'changedFrom',
      key: 'changedFrom',
      render: changedFrom => changedFrom || 'N/A',
      sorter: (a, b) => (a.changedFrom && b.changedFrom ? a.changedFrom.localeCompare(b.changedFrom) : 0),
      width: '120px'
    },
    {
      title: 'Changed To',
      dataIndex: 'changedTo',
      key: 'changedTo',
      render: changedTo => changedTo || 'N/A',
      sorter: (a, b) => (a.changedTo && b.changedTo ? a.changedTo.localeCompare(b.changedTo) : 0),
      width: '120px'
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: updatedBy => updatedBy || 'N/A',
      sorter: (a, b) => (a.updatedBy && b.updatedBy ? a.updatedBy.localeCompare(b.updatedBy) : 0),
      width: '80px'
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      if (sorter.order === 'ascend') {
        setSort({ asc: [sorter.field] });
      } else {
        setSort({ desc: [sorter.field] });
      }
    }
    if (extra.action === 'paginate') {
      setCurrentPage(pagination?.current);
    }
  };

  const handleExport = () => {
    let payload = {};
    let formattedDates = [];
    if (selectedDates) {
      formattedDates = [
        moment(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        moment(selectedDates[1])
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      ];
      payload.changeAt = formattedDates;
    }

    dispatch(
      actionCreators.fetchAllChangeLogDetails({
        reportType: selectedOption,
        searchParams: payload,
        sortParams: {},
        pageSize: 50,
        totalPages: changeLog?.data?.pageCount
      })
    );
  };

  return (
    <>
      {/* Modal component - Do Not Convert Brand List */}
      <Modal title="Change Log Details" visible={isVisible} onCancel={handleModalCancel} footer={null} width={'80%'}>
        {/* Use Spin component to show loading indicator */}
        <Spin spinning={isLoadingChangeLog || isLoadingAllChangeLog}>
          {/* Modal content */}
          <Form form={form} component={false}>
            {/* Top Controls: Date Range Picker and Dropdown */}
            <Form
              form={form}
              layout="inline"
              onFinish={handleSearch} // Triggers when the form is submitted
              style={{ marginBottom: 16 }}
            >
              <Form.Item name="dateRange" style={{ marginRight: 16 }}>
                <DatePicker.RangePicker onChange={handleDateChange} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="dropdown" style={{ marginRight: 16 }}>
                <Select placeholder="Select an option" onChange={handleDropdownChange} style={{ width: 200 }}>
                  <Select.Option value="pairs">Pair History</Select.Option>
                  <Select.Option value="doNotConvertBrands">Do Not Convert Brand History</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                shouldUpdate // Ensures the item rerenders on field updates
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !(
                      selectedDates &&
                      selectedDates.length === 2 &&
                      selectedDates.some(date => date !== '') &&
                      selectedOption
                    )
                  }
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
            {changeLogRecords && changeLogRecords.length > 0 ? (
              <Table
                columns={selectedOption?.toLowerCase() === 'pairs' ? columnsPairs : columnsDoNotConvertBrand}
                dataSource={changeLogRecords}
                pagination={{
                  total: changeLog?.data?.total, // Provide the total number of items
                  current: currentPage,
                  pageSizeOptions: [],
                  showSizeChanger: false,
                  pageSize: pageSize
                }}
                scroll={{ x: 'max-content' }}
                onChange={onChange}
                className="sgt-pairs"
              />
            ) : (
              <div>No data available</div> // Replace this with a placeholder, message, or nothing
            )}
            <Row justify="end" gutter={16} style={{ marginTop: 16 }}>
              <Col>
                <Button
                  type="primary"
                  disabled={!(changeLogRecords && changeLogRecords.length > 0)}
                  onClick={handleExport}
                >
                  Export
                </Button>
              </Col>
              <Col>
                <Button key="clear" onClick={handleModalCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeLogModal;
