import { SAVE_RAINBOW_ATTRIBUTES, UPDATE_SUGGESTED_NEW_PAIRS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { postRequest } from '_http';
import { action } from 'reduxHelpers';

// add rainbow attributes..
function* saveRainbowAttributeSagaAsync({ payload }) {
  try {
    debugger
    const response = yield postRequest(`/brandconversion/rainbow-attributes`, payload);
    console.log('data', response);
    yield put({ type: SAVE_RAINBOW_ATTRIBUTES.SUCCESS, payload: { response } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Rainbow attributes updated successfully.',
        className: 'success',
        message: 'SUCCESS: Rainbow attributes Updated'
      })
    );
  } catch (error) {
    console.log('inside api call error ', error?.response?.data?.description);
    yield put({ type: SAVE_RAINBOW_ATTRIBUTES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error?.response?.data?.description,
        className: 'error',
        message: "COULDN'T UPDATE OR SAVE RAINBOW ATTRIBUTES"
      })
    );
  }
}

export { saveRainbowAttributeSagaAsync };
