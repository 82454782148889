import { all } from 'redux-saga/effects';
import notificationSaga from './notificationSaga';
import userSaga from './userSaga';
import slottedListSaga from './slottedListSaga';
import businessCentersSaga from './businessCentersSaga';
import itemGroupsSaga from './itemGroupsSaga';
import attributeGroupsSaga from './attributeGroupsSaga';
import itemListSaga from './itemListSaga';
import pairTypesSaga from './pairTypesSaga';
import doNotConvertBrandsSaga from './doNotConvertBrandsSaga';
import supcListSaga from './supcListSaga';
import newPairSaga from './newPairSaga';
import itemChangesSaga from './itemChangesSaga';
import validAttributesSaga from './validAttributesSaga';
import validateNewPairSaga from './validateNewPairSaga';
import qualifierSaga from './qualifierSaga';
import matchQualifierSaga from './matchQualifierSaga';
import suggestedPairListSaga from './suggestedPairListSaga';
import updateSuggestedPairSaga from './updateSuggestedPairsSaga';
import changeLogSaga from './changeLogSaga';
import allChangeLogSaga from './allChangeLogSaga';
import rainbowAttributeSaga from './rainbowAttributesSaga';
import saveRainbowAttributeSaga from './saveRainbowAttributeSaga';

export default function* rootSaga() {
  yield all([
    ...notificationSaga,
    ...userSaga,
    ...slottedListSaga,
    ...businessCentersSaga,
    ...itemGroupsSaga,
    ...attributeGroupsSaga,
    ...itemListSaga,
    ...pairTypesSaga,
    ...doNotConvertBrandsSaga,
    ...supcListSaga,
    ...newPairSaga,
    ...itemChangesSaga,
    ...validAttributesSaga,
    ...validateNewPairSaga,
    ...qualifierSaga,
    ...matchQualifierSaga,
    ...suggestedPairListSaga,
    ...updateSuggestedPairSaga,
    ...changeLogSaga,
    ...allChangeLogSaga,
    ...rainbowAttributeSaga,
    ...saveRainbowAttributeSaga
  ]);
}
