import { ALL_CHANGE_LOGS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

function* fetchAllChangeLogsAsync({ reportType, searchParams,sortParams, pageSize, totalPages }) {
  let allData = [];
  let pageNo = 0; // Start at page 0

  try {
    do {
      console.log(`Fetching page ${pageNo}`);
      const _sortParams =
        sortParams && Object.keys(sortParams).length > 0
          ? encodeURIComponent(JSON.stringify(sortParams))
          : encodeURIComponent(JSON.stringify({ desc: ['changeAt'] }));
      const _filter = searchParams
        ? encodeURIComponent(JSON.stringify(searchParams))
        : encodeURIComponent(JSON.stringify({}));

      // Fetch a single page of data
      const changeLogData = yield getRequest(
        `/brandconversion/change-logs?type=${encodeURIComponent(
          reportType
        )}&search-param=${_filter}&sort-params=${_sortParams}&current-page=${pageNo}&page-size=${pageSize}`
      );

      // Add the data to the main array
      allData = allData.concat(changeLogData?.data?.data?.data);

      console.log(`Fetched page ${pageNo} of ${totalPages}`);
      pageNo++;
    } while (pageNo < totalPages); // Continue while pageNo is less than totalPages

    console.log('All data fetched successfully', allData);

    yield put({ type: ALL_CHANGE_LOGS.SUCCESS, payload: { allChangeLog: allData } });
  } catch (error) {
    console.log('Error fetching change log data', error);
    yield put({ type: ALL_CHANGE_LOGS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH CHANGE LOG DETAILS"
      })
    );
  }
}

export { fetchAllChangeLogsAsync };
