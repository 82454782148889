import initialState from 'store/initialState';
import { SAVE_RAINBOW_ATTRIBUTES, UPDATE_SUGGESTED_NEW_PAIRS } from 'actions/actionTypes';

const saveRainbowAttributeReducer = (state = initialState.saveRainbowAttributes, action) => {
  switch (action.type) {
    case SAVE_RAINBOW_ATTRIBUTES.REQUEST:
      return { ...state, isSavingAttribute: true };
    case SAVE_RAINBOW_ATTRIBUTES.SUCCESS:
      return {
        ...state,
        isSavingAttribute: false,
        saveRainbowAttributes: { ...state.saveRainbowAttributes, saveRainbowAttributes: action.payload.response.data }
      };
    case SAVE_RAINBOW_ATTRIBUTES.FAILURE:
      return { ...state, isSavingAttribute: true};
    default:
      return state;
  }
};

export default saveRainbowAttributeReducer;
