import initialState from 'store/initialState';
import { ALL_CHANGE_LOGS } from 'actions/actionTypes';

const allChangeLog = (state = initialState.allChangeLog, action) => {
  switch (action.type) {
    case ALL_CHANGE_LOGS.REQUEST:
      return { ...state, isLoadingAllChangeLog: true };
    case ALL_CHANGE_LOGS.SUCCESS:
      return {
        ...state,
        isLoadingAllChangeLog: false,
        allChangeLog: action.payload.allChangeLog 
      };
    case ALL_CHANGE_LOGS.FAILURE:
      return { ...state, isLoadingAllChangeLog: false };
    default:
      return state;
  }
};

export default allChangeLog;
