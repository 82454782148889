import initialState from 'store/initialState';
import { RAINBOW_ATTRIBUTES } from 'actions/actionTypes';

const rainbowAttributes = (state = initialState.rainbowAttributes, action) => {
  switch (action.type) {
    case RAINBOW_ATTRIBUTES.REQUEST:
      return { ...state, isLoading: true };
    case RAINBOW_ATTRIBUTES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        rainbowAttributes: action.payload.rainbowAttributes
      };
    case RAINBOW_ATTRIBUTES.FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default rainbowAttributes;
