import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';

import ItemDetailsTable from 'components/ItemDetailsTable';
import SearchFilters from 'components/SearchFilters';
import AttributeMaintenance from 'components/AttributeMaintenance';

export default function BrandConversionView() {
  const [selectedMainFilterValues, setSelectedMainFilterValues] = useState(null);
  const pairTypes = useSelector(state => _.get(state.pairTypes, 'pairTypes', []));

  const [showBrandConversion, setShowBrandConversion] = useState(true);
  // Define the function to be passed to SearchFilters
  const handleSearch = payload => {
    // Update state with selected values
    // setSelectedValues(values);
    setSelectedMainFilterValues(payload);
    console.log('at parent c' + payload);
    // Call the function in ItemDetailsTable
    if (itemDetailsTableFunction) {
      itemDetailsTableFunction(payload);
    }
  };

  // Define a function to receive the itemDetailsTableFunction from ItemDetailsTable
  const setItemDetailsTableFunction = func => {
    itemDetailsTableFunction = func;
  };
  let itemDetailsTableFunction;

  const toggleView = () => {
    setShowBrandConversion(!showBrandConversion);
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%' }} hidden={!showBrandConversion}>
        <SearchFilters />
        {!_.isEmpty(pairTypes) && <ItemDetailsTable toggleView={toggleView} />}
      </div>

      <div style={{ width: '100%' }} hidden={showBrandConversion}>
        <AttributeMaintenance toggleView={toggleView} />
      </div>
    </React.Fragment>
  );
}
