import * as _ from 'lodash';
import { bindActionCreators } from 'redux';
import PhoneInput from 'react-phone-number-input';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Select, Spin, Modal } from 'antd';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { LoadingOutlined, CloseCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import * as actionCreators from '../actions';

const { confirm } = Modal;
const { Option } = Select;
const PHONE_TYPES = ['Work', 'Mobile', 'Home', 'Primary'];

export default function AgentDetails(props) {
  const dispatch = useDispatch();
  const { saveAgent, setCardType, deleteAgent } = bindActionCreators(actionCreators, dispatch);

  // Redux State
  const isFetchingAgentDetails = useSelector(state => _.get(state, 'agents.fetchingAgentDetails', false));
  const isCreatingAgent = useSelector(state => _.get(state, 'agents.isCreatingAgent', false));
  const isDeletingAgent = useSelector(state => _.get(state, 'agents.isDeletingAgent', false));
  const market = useSelector(state => _.get(state, 'user.data.associateMarket.market', ''));

  // Local state
  const [contactId, setcontactId] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [networkId, setNetworkId] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [phones, setPhones] = useState([{ phoneId: new Date().valueOf(), number: '', type: PHONE_TYPES[0] }]);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [site, setSite] = useState('');
  const { onClose, cardType } = props;
  const disabled = isCreatingAgent || isDeletingAgent;

  useEffect(() => {
    if (!_.isEmpty(props.contact)) {
      assignDefaultValues();
    }
  }, [props.contact]);

  const assignDefaultValues = () => {
    setcontactId(props.contact.contactId);
    setName(props.contact.name);
    setTitle(props.contact.title);
    setEmail(props.contact.email);
    setPhones(props.contact.phone);
    setNetworkId(props.contact.networkId || props.contact.contactId);
    setSite(props.contact.site);
  };

  // Fix for the invalid phone issue
  const skipPhoneValidation = phoneObj => {
    return checkForSkippingPhoneValidation(cardType, props, phoneObj);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleFilled />,
      content: `Name: ${name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(contactId);
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const getMainTitle = () => {
    let title = null;

    switch (cardType) {
      case 'NEW':
        title = 'NEW SALES CONSULTANT';
        break;
      case 'UPDATE':
        title = 'UPDATE CONTACT';
        break;
    }

    return title;
  };

  const isAllDataValid = () => {
    let isAllPhonesValid = true;
    let { validEmail } = isEmailValid();

    _.forEach(phones, obj => {
      if (!isPhoneValid(obj)) {
        isAllPhonesValid = false;
      }
    });

    if (cardType === 'UPDATE') validEmail = true;

    if (isNameValid() && isTitleValid() && isNetworkIdValid() && validEmail && isAllPhonesValid && isSiteValid()) {
      return true;
    }
  };

  const isNameValid = () => {
    return !_.isEmpty(name);
  };

  const isTitleValid = () => {
    return !_.isEmpty(title);
  };

  const isNetworkIdValid = () => {
    if (_.isEmpty(networkId)) {
      return false;
    }
    return `${networkId}`.trim().length == 8;
  };

  const isSiteValid = () => {
    return !_.isEmpty(site);
  };

  const isEmailValid = () => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return checkEmailValidity(regex, email, confirmationEmail);
  };

  const isPhoneValid = phoneObj => {
    if (skipPhoneValidation(phoneObj)) return true;
    if (!_.isEmpty(phoneObj.number) && isPossiblePhoneNumber(phoneObj.number)) return true;
  };

  const onSave = () => {
    let contact = {
      name,
      title,
      email,
      phone: phones,
      market,
      networkId,
      update: false,
      site
    };

    if (isAllDataValid()) {
      saveAgent(contact);
    } else {
      setShowValidationMessage(true);
    }
  };

  const onUpdate = () => {
    let contact = {
      name,
      title,
      email,
      phone: phones,
      market,
      networkId,
      update: true,
      site
    };

    if (isAllDataValid()) {
      saveAgent(contact);
      setCardType('DETAILS');
    } else {
      setShowValidationMessage(true);
    }
  };

  const onDelete = () => {
    deleteAgent(contactId);
  };

  const onClickEdit = () => {
    setCardType('UPDATE');
  };

  const onCancel = () => {
    setCardType('DETAILS');
    assignDefaultValues();
  };

  const onChangeName = e => {
    setName(e.target.value);
  };

  const onChangeNetworkId = e => {
    setNetworkId(e.target.value);
  };

  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangeConfirmationEmail = e => {
    setConfirmationEmail(e.target.value);
  };

  const onChangePhone = (phoneId, value) => {
    const index = _.findIndex(phones, o => {
      return phoneId === o.phoneId;
    });
    if (index !== -1) {
      let phonesClone = [...phones];
      let newPhone = { ...phones[index], ...value };
      phonesClone[index] = newPhone;
      setPhones(phonesClone);
    }
  };

  const onChangeSite = value => {
    setSite(value);
  };

  const onAddPhone = () => {
    let phonesClone = [...phones];
    phonesClone.push({ phoneId: new Date().valueOf(), number: '', type: PHONE_TYPES[0] });
    setPhones(phonesClone);
  };

  const onDeletePhone = phoneId => {
    let remainingPhones = _.filter(phones, o => {
      return phoneId !== o.phoneId;
    });
    setPhones(remainingPhones);
  };

  const renderTitleSection = () => {
    if (cardType === 'NEW' || cardType === 'UPDATE') {
      let invalidName = !isNameValid() && showValidationMessage;
      let invalidTitle = !isTitleValid() && showValidationMessage;
      let invalidNetworkId = !isNetworkIdValid() && showValidationMessage;
      let invalidSite = !isSiteValid() && showValidationMessage;

      return (
        <div className="title-section top-title-section">
          <div className="input-details-name">
            <Input
              className={invalidName ? 'invalid-field' : ''}
              placeholder="Name"
              value={name}
              onChange={onChangeName}
            />
            {displayValidationErrors(invalidName, 'Name cannot be empty')}
          </div>
          <div className="input-details-name">
            <Input
              className={invalidNetworkId ? 'invalid-field' : ''}
              placeholder="Network Id"
              value={networkId}
              onChange={onChangeNetworkId}
              disabled={cardType === 'UPDATE'}
            />
            {displayValidationErrors(invalidNetworkId, `Network Id should be valid`)}
          </div>
          <div className="input-contact-title">
            <Input
              className={invalidTitle ? 'invalid-field' : ''}
              placeholder="Title"
              value={title}
              onChange={onChangeTitle}
            />
            {displayValidationErrors(invalidTitle, 'Title cannot be empty')}
          </div>

          <div className="select-site">
            <Select
              className={invalidSite ? 'invalid-field' : ''}
              dropdownStyle={{ width: '400px' }}
              showSearch
              optionFilterProp="children"
              value={site}
              onChange={onChangeSite}
            >
              <Option value="" disabled>
                Select a Site
              </Option>
              {props.sites.map((site, index) => (
                <Option key={index} value={site}>
                  {site}
                </Option>
              ))}
            </Select>
            {displayValidationErrors(invalidSite, 'Site cannot be empty')}
          </div>
        </div>
      );
    }
    return (
      <div className="title-section top-title-section">
        <div className="details-name">{name ? `${name} (${contactId})` : contactId}</div>
        <div className="details-contact-site">{site || '[NO SITE]'}</div>
        <div className="details-contact-title">{title || '[NO TITLE]'}</div>
      </div>
    );
  };

  const renderContactSection = () => {
    let { validEmail, validationMessage, validationCode } = isEmailValid();
    if (cardType === 'NEW' || cardType === 'UPDATE') {
      return (
        <React.Fragment>
          {cardType === 'NEW' ? (
            <React.Fragment>
              <div className="cd-field-name">Email</div>
              <div className="cd-info-msg">A new user is created with the email provided</div>

              <Input
                className={`input-email ${validationCode === 1 && showValidationMessage ? 'invalid-field' : ''}`}
                placeholder="Email Address"
                value={email}
                onChange={onChangeEmail}
              />

              <Input
                className={`input-confirm-email ${
                  validationCode === 2 && showValidationMessage ? 'invalid-field' : ''
                }`}
                placeholder="Confirm Email Address"
                value={confirmationEmail}
                onChange={onChangeConfirmationEmail}
              />

              {!validEmail && showValidationMessage && <div className="validation-msg">{validationMessage}</div>}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="cd-field-name">Email</div>
              <div className="cd-info-msg">Email cannot be changed</div>
              <div className="cd-email">{email}</div>
            </React.Fragment>
          )}

          <div className="phones-section">
            <div className="cd-field-name">Phones</div>
            {phones.map((obj, index) => {
              let validPhone = !isPhoneValid(obj) && showValidationMessage;
              return (
                <div key={obj.phoneId}>
                  <div className="update-phone-wrapper">
                    <PhoneInput
                      size="small"
                      placeholder="Number"
                      className={`phone-input-textbox ${validPhone ? 'invalid-field' : ''}`}
                      defaultCountry="US"
                      value={obj.number}
                      onChange={value => onChangePhone(obj.phoneId, { number: value })}
                    />

                    <Select defaultValue={obj.type} onChange={value => onChangePhone(obj.phoneId, { type: value })}>
                      {PHONE_TYPES.map(opt => {
                        return (
                          <Option value={opt} key={opt}>
                            {opt}
                          </Option>
                        );
                      })}
                    </Select>
                    {phones.length !== 1 && (
                      <div className="btn-remove-phone" onClick={() => onDeletePhone(obj.phoneId)}>
                        x
                      </div>
                    )}
                  </div>
                  {displayValidationErrors(validPhone, 'Invalid phone number')}
                </div>
              );
            })}
          </div>

          <div className="btn-add-phone">
            <Button type="primary" onClick={onAddPhone}>
              Add Phone
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="cd-field-name">Email</div>
          <div className="cd-email">{email || '[NO EMAIL]'}</div>
          <div className="cd-field-name">Phones</div>
          <div className="phones-section">
            {phones.length > 0 ? (
              phones.map(obj => {
                return (
                  <div key={obj.phoneId} className="phones-wrapper">
                    <div className="phone-number">{obj.number}</div>
                    <div className="phone-option">{obj.type}</div>
                  </div>
                );
              })
            ) : (
              <>{'[NO PHONES]'}</>
            )}
          </div>
        </React.Fragment>
      );
    }
  };

  const renderCardContent = () => {
    if (!isFetchingAgentDetails) {
      return (
        <div className={`card-content ${disabled ? 'disabled' : ''}`}>
          {/* <div className="close-icon" onClick={onClose} >
            <CloseCircleOutlined style={{ color: '#6a737b' }} />
          </div> */}
          <div className="content-wrapper">
            {mainTitle && <div className="cd-main-title">{mainTitle}</div>}

            {renderTitleSection()}

            <div className="contact-section">
              <div className="contact-details-titles">Contact Information</div>
              {renderContactSection()}
            </div>
          </div>

          <div className={`action-btn-group ${cardType === 'DETAILS' ? 'center' : ''}`}>{renderActionButtons()}</div>
        </div>
      );
    } else {
      return (
        <div className="card-content">
          <div className="empty-content">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </div>
        </div>
      );
    }
  };

  const renderActionButtons = () => {
    return (
      <div>
        {cardType !== 'UPDATE' && (
          <Button className="action-btn cancel-btn" onClick={onClose}>
            CLOSE
          </Button>
        )}
        {cardType === 'NEW' && (
          <Button type="primary" loading={isCreatingAgent} className="action-btn save-btn" onClick={onSave}>
            CREATE
          </Button>
        )}
        {cardType === 'DETAILS' && (
          <Button type="danger" className="action-btn" loading={isDeletingAgent} onClick={showDeleteConfirm}>
            DELETE
          </Button>
        )}
        {cardType === 'DETAILS' && (
          <Button type="primary" className="action-btn" onClick={onClickEdit}>
            EDIT
          </Button>
        )}
        {cardType === 'UPDATE' && (
          <Button className="action-btn cancel-btn" onClick={onCancel}>
            CANCEL
          </Button>
        )}
        {cardType === 'UPDATE' && (
          <Button type="primary" className="action-btn save-btn" onClick={onUpdate}>
            SAVE
          </Button>
        )}
      </div>
    );
  };

  const mainTitle = getMainTitle();

  return (
    <React.Fragment>
      <div className="contact-details-overlay" onClick={onClose} />
      {disabled && <div className="disabled-overlay" />}
      <div className="contact-details-card">{renderCardContent()}</div>
    </React.Fragment>
  );
}

function checkForSkippingPhoneValidation(cardType, props, phoneObj) {
  if (cardType === 'UPDATE') {
    const existingPhones = _.get(props.contact, 'phone', []);
    const index = _.findIndex(existingPhones, o => phoneObj.phoneId === o.phoneId);

    if (index !== -1) {
      if (existingPhones[index].number === phoneObj.number) return true;
    }
  }
}

function checkEmailValidity(regex, email, confirmationEmail) {
  if (!regex.test(String(email).toLowerCase())) {
    return { validEmail: false, validationCode: 1, validationMessage: 'Invalid email address' };
  } else if (email !== confirmationEmail) {
    return { validEmail: false, validationCode: 2, validationMessage: 'Email and confirmation email mismatch' };
  }

  return { validEmail: true };
}

function displayValidationErrors(condition, errorMessage) {
  return condition && <div className="validation-msg">{errorMessage}</div>;
}
