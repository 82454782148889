import { takeLatest } from 'redux-saga/effects';
import { AGENTS, AGENT_DETAILS, CREATE_AGENT, DELETE_AGENT, LOAD_SITE } from 'actions/actionTypes';
import { loadAgentsAsync, loadAgentDetailsAsync, saveAgentAsync, deleteAgentAsync, loadSitesAsync } from './AgentsSaga';

const agents = [takeLatest(AGENTS.REQUEST, loadAgentsAsync)];
const agentDetails = [takeLatest(AGENT_DETAILS.REQUEST, loadAgentDetailsAsync)];
const saveAgent = [takeLatest(CREATE_AGENT.REQUEST, saveAgentAsync)];
const deleteAgent = [takeLatest(DELETE_AGENT.REQUEST, deleteAgentAsync)];
const loadSites = [takeLatest(LOAD_SITE.REQUEST, loadSitesAsync)];

export { agents, agentDetails, saveAgent, deleteAgent, loadSites };
