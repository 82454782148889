import * as _ from 'lodash';
import initialState from 'store/initialState';
import { AGENTS, AGENT_DETAILS, CREATE_AGENT, CARD_TYPE, DELETE_AGENT, LOAD_SITE } from 'actions/actionTypes';

const updateAgentList = (list, data) => {
  let newList = [...list];
  if (data.update) {
    newList = newList.map(listItem => {
      if (listItem.contactId === data.contactId) {
        listItem = { ...listItem, ...data };
        console.log(`matched ------****`, listItem);
      }
      return listItem;
    });
  } else {
    newList = [...list, data];
  }
  return newList;
};

const agents = (state = initialState.agents, action) => {
  switch (action.type) {
    case AGENTS.REQUEST:
      return { ...state, fetchingAgents: true };
    case AGENTS.SUCCESS:
      return { ...state, fetchingAgents: false, list: action.payload };
    case AGENTS.FAILURE:
      return { ...state, fetchingAgents: false };
    case AGENT_DETAILS.REQUEST:
      return { ...state, fetchingAgentDetails: true, selectedAgent: {} };
    case AGENT_DETAILS.SUCCESS:
      return { ...state, fetchingAgentDetails: false, selectedAgent: action.payload };
    case AGENT_DETAILS.FAILURE:
      return { ...state, fetchingAgentDetails: false };
    case CREATE_AGENT.REQUEST:
      return { ...state, isCreatingAgent: true };
    case CREATE_AGENT.SUCCESS:
      return { ...state, isCreatingAgent: false, list: updateAgentList(state.list, action.payload), cardType: null };
    case CREATE_AGENT.FAILURE:
      return { ...state, isCreatingAgent: false };
    case CARD_TYPE.REQUEST:
      return {
        ...state,
        cardType: action.payload.type,
        selectedAgent: resetSelectedAgent(action.payload.type, state.selectedAgent)
      };
    case DELETE_AGENT.REQUEST:
      return { ...state, isDeletingAgent: true };
    case DELETE_AGENT.SUCCESS:
      return {
        ...state,
        isDeletingAgent: false,
        list: removeAgentFromList(state.list, action.payload),
        cardType: null
      };
    case DELETE_AGENT.FAILURE:
      return { ...state, isDeletingAgent: false };
    case LOAD_SITE.SUCCESS:
      return { ...state, sites: action.payload };
    default:
      return state;
  }
};

const resetSelectedAgent = (cardType, selectedAgent) => {
  if (cardType === null) {
    return {};
  }

  return selectedAgent;
};

const removeAgentFromList = (list, contactId) => {
  const clone = [...list];
  return _.filter(clone, obj => obj.contactId !== contactId);
};

export default agents;
